import React, { useState, useEffect } from "react";
import "./matrix.scss";
import { connect } from "react-redux";
import NotFound from "../../../components/Errors/NotFound/NotFound";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Grid, Paper, FormControl, InputLabel, Select, MenuItem, CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Catalog from "../../../components/Categories/Catalog";
import { BorderBottom } from "@material-ui/icons";

function Matrix({ categories, match }) {
  const { t } = useTranslation();
  let history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [viscosity, setViscosity] = useState("5W-30");
  const [viscosityError, setViscosityError] = useState(false);

  const handleChange = event => {
    if (event.target.value === null) return;
    if (isLoading) return;
    setViscosity(event.target.value);
  };

  return (
    <div id={"matrixPage"}>
        <div>
          <div id={"matrixEngine"}>
            <div className={"container"}>
              <div className={"matrixData"}>
                <div className={"matrixHeader"}>
                <div className={"pageHeader"}>
                  <h1 className={"title"}>{t("matrix.title")}</h1>
                  <h3 style={{ marginBottom: 0 }}>
                    {t("matrix.text")}
                  </h3>
                </div>
                <div className={"matrixForm"}>
                  <div>
                    <FormControl variant="filled" style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Viscosity
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={viscosity}
                      onChange={handleChange}
                      label="Viscosity"
                      error={viscosityError}
                      disabled={isLoading}
                      fullWidth
                    >
                      <MenuItem value={"0W-16"}>0W-16</MenuItem>
                      <MenuItem value={"0W-20"}>0W-20</MenuItem>
                      <MenuItem value={"0W-30"}>0W-30</MenuItem>
                      <MenuItem value={"0W-40"}>0W-40</MenuItem>
                      <MenuItem value={"10W-30"}>10W-30</MenuItem>
                      <MenuItem value={"10W-40"}>10W-40</MenuItem>
                      <MenuItem value={"10W-50"}>10W-50</MenuItem>
                      <MenuItem value={"10W-60"}>10W-60</MenuItem>
                      <MenuItem value={"15W-40"}>15W-40</MenuItem>
                      <MenuItem value={"15W-50"}>15W-50</MenuItem>
                      <MenuItem value={"20W-40"}>20W-40</MenuItem>
                      <MenuItem value={"20W-50"}>20W-50</MenuItem>
                      <MenuItem value={"5W-20"}>5W-20</MenuItem>
                      <MenuItem value={"5W-30"}>5W-30</MenuItem>
                      <MenuItem value={"5W-40"}>5W-40</MenuItem>
                      <MenuItem value={"5W-50"}>5W-50</MenuItem>
                      <MenuItem value={"75W-80"}>75W-80</MenuItem>
                      <MenuItem value={"75W-90"}>75W-90</MenuItem>
                      <MenuItem value={"80W-90"}>80W-90</MenuItem>
                      <MenuItem value={"85W-140"}>85W-140</MenuItem>
                    </Select>
                  </FormControl>

                    <div style={{ clear: "both" }}></div>
                    <div
                      className={"backButton"}
                      style={{ marginBottom: 0, marginTop: 11 }}
                      onClick={() => {
                        setIsLoading(true);

                        var link = document.createElement('a');
                        link.href = "https://adminpanel.german-gold.de/matrix/download/" + viscosity;
                        link.download = 'german_gold_matrix.pdf';
                        link.dispatchEvent(new MouseEvent('click'));

                        setIsLoading(false);
                      }}
                    >
                      <span style={{ color: "#e7cb75", paddingRight: 9 }}>
                        <i className={"fas fa-caret-right"}></i>
                      </span>
                      <span>Download Matrix as PDF {isLoading && <CircularProgress size={13} style={{marginLeft: 6}} />}</span>
                    </div>
                  <div style={{ clear: "both" }}></div>
                  </div>
                </div>
                  <div
                    className={"backButton"}
                    style={{ marginBottom: 7 }}
                    onClick={() => {
                      history.push(`/`);
                    }}
                  >
                    <span style={{ color: "#e7cb75", paddingRight: 9 }}>
                      <i className={"fas fa-caret-right"}></i>
                    </span>
                    <span>Go back to the search</span>
                  </div>
                  <div style={{ clear: "both" }}></div>
                </div>
                
                  
              </div>
            </div>
          </div>
          <div className={"more"}>
            <div className={"space"}>
              <Catalog small />
            </div>
          </div>
        </div>
    </div>
  );
}

export default connect(state => ({
  categories: state.categories,
}))(Matrix);
