import React, { useState } from "react";
import { connect } from "react-redux";
import "./Downloads.scss";
import {
  Paper,
  TableContainer,
  withStyles,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  Tooltip,
  IconButton,
  Toolbar,
  Typography,
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";

const ArticleCell = withStyles(theme => ({
  head: {
    backgroundColor: "#111111",
    color: "#ffffff",
    fontWeight: "bold",
    fontSize: "16px",
  },
  body: {
    backgroundColor: "#a09f9f",
    fontSize: "16px",
  },
}))(TableCell);

function Downloads({ product, loading = false }) {
  const [isLoading, setIsLoading] = useState(loading);
  const [media, setMedia] = useState(product.media);

  return (
    <div className={"fileWrapper"}>
      <Paper style={{ overflow: "hidden" }}>
        <Toolbar className={"bar"}>
          <Typography className={"title"}>Downloads</Typography>
        </Toolbar>
        <TableContainer>
          <Table className={"fileTable"} style={{ overflow: "hidden" }}>
            <TableHead>
              <TableRow>
                <ArticleCell>Name</ArticleCell>
                <ArticleCell>Type</ArticleCell>
                <ArticleCell></ArticleCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {media.map((item, key) => {
                if (item.type == 1) {
                  return;
                }

                if (
                  item.type == 2 &&
                  item.flag !== "1" &&
                  item.secondary !== "1"
                ) {
                  return;
                }

                return (
                  <TableRow key={item.id}>
                    <ArticleCell style={{ width: "50%" }}>
                      {item.original_name}
                    </ArticleCell>
                    <ArticleCell>
                      {item.gga_type == 2 && <span>PDS</span>}
                      {item.gga_type == 3 && <span>MSDS</span>}
                    </ArticleCell>
                    <ArticleCell>
                      <a
                        href={"https://adminpanel.german-gold.de/" + item.path}
                        target="_BLANK"
                        rel="noopener noreferrer"
                      >
                        <Tooltip title={"Download this file"}>
                          <IconButton aria-label="download">
                            <GetAppIcon />
                          </IconButton>
                        </Tooltip>
                      </a>
                    </ArticleCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export default connect(state => ({
  categories: state.categories,
}))(Downloads);
