import React, { useState, useEffect } from "react";
import "./product.scss";
import { connect } from "react-redux";
import NotFound from "../../../components/Errors/NotFound/NotFound";
import { useHistory } from "react-router-dom";
import api from "../../../utils/api";
import { Grid } from "@material-ui/core";
import ReactHtmlParser from "react-html-parser";
import Articles from "../../../components/Product/Articles/Articles";
import Catalog from "../../../components/Categories/Catalog";
import Downloads from "../../../components/Product/Downloads/Downloads";
import { useLightbox, SRLWrapper } from "simple-react-lightbox";
import { useSnackbar } from "notistack";
import Cookies from "js-cookie";

function Product({ categories, match }) {
  let history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [productRequestSent, setProductRequestSent] = useState(false);
  const [productRoute, setProductRoute] = useState(null);

  const [product, setProduct] = useState(null);
  const { openLightbox } = useLightbox();
  const { enqueueSnackbar } = useSnackbar();

  let search = window.location.search;
  let params = new URLSearchParams(search);

  useEffect(() => {
    setProductRoute(match.params.product);
    setProductRequestSent(false);
  }, [categories, match.params.product]);


  if (!match.params.product !== null && !productRequestSent) {
    setProductRequestSent(true);
    var bodyFormData = new FormData();

    bodyFormData.set("a", match.params.product);
    bodyFormData.set("c", "en");

    api(
      `GetArticle`,
      bodyFormData,
      {},
      {
        "x-api-authorization": "GGAV-APIX-J0HG-UA90-02QQ",
        "Content-Type": "multipart/form-data",
      }
    )
      .then(result => {
        setProduct(result.data.result.values);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        console.error(err);
      });
  }

  if (product !== null && !isLoading) {
    let image = product.view.media.find(
      x => x.type === "1",
      x => x.flag === "1"
    );

    const gallery = product.view.media.filter(value => value.gga_type === "0");
    let categoryData = categories.find(obj => obj.id == product[0].artikel_cat);

    if (!categoryData) {
      return (
        <div>
          <div className={"container"} style={{ padding: 30 }}>
            <i
              style={{
                fontSize: 40,
                color: "#ffffff",
                textAlign: "center",
                margin: "0 auto",
                display: "block",
              }}
              class={"fas fa-spinner fa-spin"}
            ></i>
          </div>
        </div>
      );
    }

    return (
      <div className={"productPage"}>
        <div className={"container"}>
          <div className={"productData"}>
            <div className={"productShowcase"}>
              <Grid container spacing={5}>
                <Grid item sm={12} md={4}>
                  <div>
                    {image !== null && image !== undefined && (
                      <img
                        className={"productImage"}
                        alt={product[0].artikel_name}
                        src={`https://adminpanel.german-gold.de/${image.path}`}
                      />
                    )}
                  </div>
                  <div className={"gallery"}>
                    <SRLWrapper>
                      <Grid container spacing={1}>
                        {gallery.map((galleryItem, key) => {
                          const imageStyle = {
                            backgroundImage:
                              "url(https://admin.german-gold.de/" +
                              galleryItem.path +
                              ")",
                          };

                          return (
                            <Grid item sm={6} md={2}>
                              <div className={"galleryItem"}>
                                <div
                                  className={"placement"}
                                  style={imageStyle}
                                  onClick={() => openLightbox(key)}
                                />
                              </div>

                              <img
                                style={{ display: "none" }}
                                src={`https://admin.german-gold.de/${galleryItem.path}`}
                                alt={galleryItem.original_name}
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    </SRLWrapper>
                  </div>
                </Grid>
                <Grid item sm={12} md={8}>
                  <div className={"pageHeader"}>
                    <h2 className={"crumb"}>
                      {categoryData.name}
                      {product[0].artikel_compilation == 1 && (
                        <div className={"compilation"}>Fully Synthetic</div>
                      )}
                      {product[0].artikel_compilation == 2 && (
                        <div className={"compilation"}>Semi Synthetic</div>
                      )}
                      {product[0].artikel_compilation == 3 && (
                        <div className={"compilation"}>Mineral</div>
                      )}
                    </h2>
                    <h1 className={"title"}>{product[0].artikel_name}</h1>
                    <h3>{ReactHtmlParser(product.view.content[0].text)}</h3>
                    <h3>
                      {ReactHtmlParser(product.view.content[0].instructions)}
                    </h3>
                  </div>
                  <div
                    className={"backButton"}
                    onClick={() => {
                      if (params.get("ref") === "search") {
                        history.push(`/?q=${params.get("q")}`);
                      } else if (params.get("ref") === "category") {
                        history.push(
                          `/products/category/${
                            categoryData !== undefined
                              ? categoryData.short
                              : "0"
                          }/${
                            categoryData !== undefined
                              ? categoryData.name
                              : "German Gold"
                                  .replace(/\s+/g, "-")
                                  .toLowerCase()
                          }`
                        );
                      }
                    }}
                  >
                    <span style={{ color: "#e7cb75", paddingRight: 9 }}>
                      <i class={"fas fa-caret-right"}></i>
                    </span>
                    <span>
                      Go back{" "}
                      {params.get("ref") === "search"
                        ? "to your search"
                        : "to the category"}
                    </span>
                  </div>

                  {product[0].artikel_class &&  <div
                    className={"backButton"}
                    style={{marginRight: 20}}
                    onClick={() => {
                      var link = document.createElement('a');
                      link.href = "https://admin.german-gold.de/matrix/download/" + product[0].artikel_class;
                      link.download = 'german_gold_matrix.pdf';
                      link.dispatchEvent(new MouseEvent('click'));
                    }}
                  >
                    <span style={{ color: "#e7cb75", paddingRight: 9 }}>
                      <i class={"fas fa-caret-right"}></i>
                    </span>
                    <span>
                      Download {product[0].artikel_class} Matrix
                    </span>
                  </div>
                  }

                  <div
                    className={"backButton"}
                    style={{marginRight: 20}}
                    onClick={() => {
                      setWatchlist([{name: product[0].artikel_name, id: product[0].id}]);
                      enqueueSnackbar(`${product[0].artikel_name} successfully added to your watchlist.`);
                    }}
                  >
                    <span style={{ color: "#ffffff", paddingRight: 3, paddingLeft: 3 }}>
                      <i class={"fas fa-heart"}></i>
                    </span>
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={"productArticles"}>
              <Articles product={product.view} />
            </div>
            <div className={"productDownloads"}>
              <Downloads product={product.view} />
            </div>
          </div>
        </div>
        <div className={"more"}>
          <Catalog small />
        </div>
      </div>
    );
  }

  if (product === null && !isLoading) {
    return <NotFound />;
  }

  if (isLoading) {
    return (
      <div>
        <div className={"container"} style={{ padding: 30 }}>
          <i
            style={{
              fontSize: 40,
              color: "#ffffff",
              textAlign: "center",
              margin: "0 auto",
              display: "block",
            }}
            class={"fas fa-spinner fa-spin"}
          ></i>
        </div>
      </div>
    );
  }
}

function setWatchlist(items) {
  const existingCookie = Cookies.get("_GGC_ITEMS");
  let cookieValue = null;

  if (existingCookie === undefined || existingCookie === null) {
    cookieValue = [];
  } else {
    cookieValue = JSON.parse(existingCookie);
  }

  items.map(item => {
    if (cookieValue.some(citem => citem.name == item.name)) {
      // enqueueSnackbar("Product already in your collection.");
      return;
    }

    cookieValue.push(item);
    // enqueueSnackbar("Product added to your collection.");
  });

  Cookies.set("_GGC_ITEMS", JSON.stringify(cookieValue), {
    expires: 7,
  });

}

export default connect(state => ({
  categories: state.categories,
}))(Product);
